@import '../../theme/Colors.scss';

.app_button {
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $yellow-secondary;
	border: none;
	border-radius: 30px;
	padding: 15px 30px;
	font-size: 12px;
	font-weight: 700;
	margin-top: 15px;
	cursor: pointer;
	color: white;
	gap: 15px;

	&[disabled] {
		cursor: no-drop;
		background-color: $grey-primary;
	}

	&__secondary {
		background: transparent;
		color: $yellow-secondary;
		padding: 15px 0px;
	}
}