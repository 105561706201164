@import '../../../application/theme/Mixins.scss';
@import '../../../application/theme/Colors.scss';

.app_upload_image {
	width: 100%;
	height: 100%;
	border: 1px dashed $grey-secondary;
	border-radius: 10px;
	background-color: $grey-primary;

	&.is_uploaded {
		background-color: $tea-green;
		border-color: $custom-success;
		border-style: solid;
	}

	&.is_drag_active {
		background-color: #dedede;
	}

	&__input {
		position: absolute;
  		clip: rect(0px,0px,0px,0px);
		visibility: hidden;
	}

	&__area {
		padding: 1.5rem 3rem 1.5rem 1.5rem;
		position: relative;
	}

	&__state {
		@include flexbox;
		@include flex-wrap(nowrap);
		@include align-items(center);
		@include justify-content(center);
		position: relative;
		z-index: 1;

		p {
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			
			.is_uploaded & {
				color: #10B509;
			}
		}
	}

	&__upload {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 15px;
		color: #676766;
		font-size: 16px;
		cursor: pointer;
	}

	&__upload_icon {
		margin: 0 1rem;
		display: inline-block;
		line-height: 1;
	}

	&__loaded {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		row-gap: 10px;

		.file_name {
			width: 90%;
			min-height: 69px;
			background: #EDEDED;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: solid 1px #676766;
			border-radius: 10px;
			cursor: pointer;
			gap: 10px;

			&__icon {
				width: 15%;
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			&__text {
				width: 85%;
				height: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}

		.file_upload {
			width: 100%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;
			color: #676766;
			font-size: 16px;
			cursor: pointer;

			&__text {
				text-decoration: underline;
			}
		}
	}

	&__close {
		@include center-position(y);
		background: none;
		border: none;
        cursor: pointer;
		outline: 0;
		right: 0.875rem;
		line-height: 1;
	}
}

#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}