.app_page_title {
	font-weight: 500;
	font-size: 24px;

	&__secondary {
		color: #676766;
		font-size: 28px;
		line-height: 42px;
	}

	&__inner {
		display: flex;
        justify-content: flex-start;
		align-items: center;
	}

	&__tooltip {
		line-height: 0.5625rem;
		margin-left: 0.625rem;
		display: inline-block;
		vertical-align: middle;
	}

}
