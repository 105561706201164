.home_view {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;

	&__title {
		width: 100%;
		height: 45px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	&__no_items {
		width: 62%;
		height: calc(100% - 45px);
	}

	&__new_vacancy {
		width: 38%;
		height: calc(100% - 45px);
	}
}