@import '../../theme/Colors.scss';

$px-container: 1rem;
$py-container: 0.5rem;  

.app_alert {
	padding: $py-container $px-container;
    // position: relative;
    // opacity: 0;
    // translate: 0 -0.625rem;

    &.enter-done {
        transition: all 0.25s ease-in-out;
        translate: 0 0;
        opacity: 1;
    }

    &__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__icon {
        line-height: 1px;
        width: 3.5rem;
        text-align: center;
        position: relative;
        left: $px-container * -1;
        border-right: 1px solid $white;
    }

    &__title {
        font-size: 0.875rem;
        font-weight: bold;
        margin: 0 0;
    }

    &__text {
        font-size: 0.75rem;
        color: $white;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &.is_default {
        background-color: $grey-secondary
    }

    &.is_danger {
        background-color: $custom-danger
    }

    &.is_info {
        background-color: $custom-info
    }

    &.is_success {
        background-color: $custom-success
    }

}
