@import'./../../application/theme/Colors.scss';

.home_layout {
	width: 100%;
	height: 100vh;
	display: flex;
	align-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	background-color: $background-color-primary;

	&__header {
		width: 100%;
		height: 82px;
	}

	&__body {
		width: 100%;
		height: calc(100vh - 82px);
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;

		.home_layout_lateral_menu {
			width: 82px;
			height: 100%;
		}

		.home_layout_content {
			width: calc(100% - 82px);
			height: 100%;
			overflow-y: auto;
			padding: 40px 50px;
		}
	}
}