.upload_vacancy {
	width: 100%;
	height: 100%;
	box-shadow: 0px 4px 4px rgba(39, 59, 114, 0.25);
	border-radius: 30px;
	padding: 25px 40px;
	background: white;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;

	&__title {
		font-size: 24px;
		font-weight: 500;
		margin: 0;
	}

	&__download_buttons {
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__instructions {
		width: 100%;
		font-weight: 500;
		font-size: 16px;
		color: #676766;
		margin-top: 60px;
	}

	&__drag_and_drop {
		width: 100%;
		height: 148px;
	}
}