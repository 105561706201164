@import'./../../application/theme/Mixins.scss';

.login_view {
    @include flexbox-align(xy);
    background-color: var(--custom-color-grey-light);
    min-height: 100vh;
    width: 100%;

    &__container {
        margin: 0 auto;
        max-width: 400px;
        width: 100%;
    }
}