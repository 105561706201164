@import './../../theme/Colors.scss';

.tag_button {
	width: auto;
	height: 25px;
	border-radius: 6px;
	background: $yellow-primary;
	color: $yellow-secondary;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 10px;
	font-size: 12px;
	font-weight: 400;
}

.tag_button__secondary {
	background: $grey-primary;
	color: $grey-secondary;
	font-weight: 400;
	text-transform: uppercase;
}