@import '../../../../application/theme/Mixins.scss';
@import '../../../../application/theme/Colors.scss';

.vacancy_details {
	width: 100%;

    &__section {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        width: 100%;
    }
    
    &__messages {
        margin: 0 auto;
        position: relative;
        top: -30px;
        min-height: 2.375rem;
        width: 100%;
    }

	&__messages_inner {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }

    &__title {
		width: 100%;
		display: flex;
        margin-bottom: 1.5rem;
		justify-content: flex-start;
		align-items: center;
	}

	&__resume {
		width: 42%;
		min-height: calc(100% - 45px);
	}

    &__steps {
        color: $grey-secondary;

        h3 {
            color: $grey-secondary;
            font-size: 1.25rem;
            line-height: 1.875rem;
        }
    }

    &__steps_description {
        margin: 0 auto 2.125rem;
    }

    &__steps_controls {

        h4 {
            font-size: 0.9375rem;
            line-height: 1.3125rem;
            font-weight: 500;
        }
    }

	&__preview {
		width: 50%;
		min-height: calc(100% - 45px);
        position: relative;
	}

    &__controls {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-end);
    }
    
}

.main_offer {
    
    &__preview_controls_area {
        height: 11.1875rem;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        opacity: 0;
        z-index: 2;
        transition: opacity 0.25s;

        &:hover {
            opacity: 1;
        }
    }
    
    &__preview_group_controls {
        @include align-items(center);
        @include center-position(x);
        @include flexbox();
        @include justify-content(center);
        background: $white;
        border-radius: 30px;
        border: 1px solid #F2F6FF;
        box-shadow: 0px 2px 2px rgba(190, 199, 224, 0.6);
        min-width: 7.375rem;
        padding: 0.75rem;
        top: 3.625rem;
        z-index: 1;
    }

    &__preview_control {
        background: transparent;
        border: none;
        cursor: pointer;
        line-height: 1;
        opacity: 0.7;
    }

   &__preview_logo_controls_area {
        @include align-items(center);
        @include flexbox();
        @include justify-content(center);
        transition: opacity 0.25s;
        height: 89px;
        position: absolute;
        right: 46px;
        top: 120px;
        width: 93px;
        opacity: 0;
        z-index: 2;

        &:hover {
            opacity: 1;
        }
   }

   &__preview_logo_group_controls {
        @include align-items(center);
        @include center-position(xy);
        @include flexbox();
        @include justify-content(center);
        background: $white;
        border-radius: 30px;
        border: 1px solid #F2F6FF;
        box-shadow: 0px 2px 2px rgba(190, 199, 224, 0.6);
        min-width: 76px;
        padding: 3px;
        z-index: 1;
   }
}