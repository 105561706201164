.buttons_group {
	width: 152px;
	height: 51px;
	border-radius: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 17px;
	box-shadow: 0px 2px 2px rgba(190, 199, 224, 0.6);

	.buttons_group__btn {
		width: 30px;
		height: 30px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}