@import '../../../../../../application/theme/Mixins.scss';
@import '../../../../../../application/theme/Colors.scss';

.main_offer {
  background-color: #fff;
  height: 70vh;
  max-height: calc(100vh - 180px);
  overflow-y: scroll;
  padding: 0 0 1rem;

  ol,
  ul {
    list-style: none;
    padding-left: 0;
  }

  &__wrap {
    padding: 0 0 2rem;
  }

  &__toolbar {
    background-color: var(--color-yellow);
    left: 0;
    padding: 0.75rem;
    position: relative;
    position: sticky;
    text-align: center;
    top: -0.5rem;
    z-index: 1;

    &__control {
      position: absolute;
      top: 7px;
      right: 7px;
    }

    &__control__edit {
      font-size: 12px;
      text-transform: capitalize;
    }

    >p {
      color: #fff;
      margin: 0;
    }
  }

  &__featured__container {
    position: relative;
    margin-bottom: 32px;
  }

  &__featured__image {
    background-color: #D9D9D9;
    height: 11.1875rem;
    width: 100%;
    border-bottom: 8px solid #fff;

    >img {
      -o-object-fit: cover;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__featured__brand {
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    bottom: -32px;
    border: 1px solid rgb(128, 128, 128);
    display: flex;
    height: 3.9375rem;
    justify-content: center;
    overflow: hidden;
    padding: 5px;
    position: absolute;
    right: 32px;
    width: 5.8125rem;

    img {
      max-width: 100%;
    }
  }

  &__featured__date {
    background: rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    max-width: 120px;
    padding: 2px 10px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 1rem;
    width: auto;
  }

  &__content {
    border-bottom: 2px solid #C4C4C4;
    padding: 0 0.5rem 0.5rem;
    max-width: 90%;
    margin: 0 auto;

    &__inner {
      margin: 0 auto;
      max-width: 100%;
    }

    &__id {
      small {
        color: rgb(169, 169, 169);
      }
    }

    &__title {
      font-size: 1.3125rem;
      font-weight: 600;
      color: rgb(0, 99, 56);
      margin-bottom: 0;
    }

    &__salary {
      font-size: 0.9375rem;
      line-height: 1.3;
      margin-bottom: 16px;
      margin-top: 5px;
      font-weight: 500;
    }
  }

  &__content_address {
    font-size: 12px;
    margin-bottom: 0;
    color: #676766;
    font-weight: 500;
  }

  &__salary__fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;

    // margin-left: -8px;
    // margin-right: -8px;
    input {
      font-size: 14px;
    }

    p {
      color: #f44336;
      position: relative;
      top: -2px;
      font-size: 11px;
      margin: 0;
    }
  }

  &__description {
    margin: 0 auto;
    padding: 1rem 0.5rem;
    max-width: 90%;
    min-height: 45vh;
  }

  &__checkbox__field {
    margin-top: 18px;
    position: relative;
    top: -0.3125rem;
    left: 11px;

    span:nth-of-type(2) {
      color: #000;
      font-size: 13px;
    }
  }

  &__vacancy__card {
    margin-bottom: 1rem;

    h4 {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    ul, ol {
      padding-left: 0.625rem;
      margin: 0 0 8px;
    }

    li,
    p {
      font-size: 12px;
    }

    li {
      border-color: rgb(0, 99, 56);
      padding-left: 18px;
      padding-top: 0.4rem;
      position: relative;

      &::before {
        border-color: inherit;
        border-radius: 10px;
        border-radius: 34px/28px;
        border: 1px solid;
        box-shadow: 0 0 2px rgb(0 0 0 / 20%);
        content: "";
        display: inline-block;
        height: 7px;
        left: 0;
        position: absolute;
        top: 11px;
        width: 9px;
      }
    }
  }

  &__bottom {
    margin: 0 auto;
    max-width: 80%;

    &__control {
      text-transform: capitalize;
      width: 100%;
    }
  }

  &__header {
    font: normal normal 700 var(--font-medium-lg) Poppins;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;

    @media (max-width: 450px) {
      font: normal normal 600 var(--font-medium-x2) Poppins;
    }

    &_title {
      line-height: 2.3;
    }

    &_para {
      color: var(--color-disable);
      font-weight: 600;
    }
  }

  &_title {
    margin-top: 1rem;
    font: normal normal 600 var(--font-medium-lg) Poppins;
    color: var(--color-disable);

    // @media (max-width: 450px) {
    //     font: normal normal 600 var(--font-medium) Poppins;
    // }
  }

  &_container {

    &_channels {
      display: flex;
      margin: 1rem 0;
      border: 1px solid var(--color-disable-extra-light);
      border-radius: 8px;
      padding: 1rem;
      justify-content: space-between;

      &_logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50%;
        width: 50%;
        height: 4rem;
        margin: 0;
      }

      &_date {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        color: var(--color-disable);
        font: normal normal 600 var(--font-small) Poppins;

        &_format {
          display: flex;
          align-items: center;
          margin-left: .3rem;

        }

        &_hour {
          display: flex;
          align-items: center;
          margin-left: .7rem;

          &_para:last-child {
            margin-left: .2rem;
          }
        }
      }
    }
  }
}

.customHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  user-select: none;

  p {
    font: normal normal 600 16px Poppins;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-admin-primary);

    p {
      margin-right: 0.5rem;
    }

    &:hover {
      cursor: pointer;

      p {
        text-decoration: underline;
        font: normal normal 600 16px Poppins;
      }
    }
  }
}