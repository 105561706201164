.app_spinner {
  display: grid;
  justify-content: center;
  align-items: center;
  line-height: 1;
  // &__figure {
  //     width: 50px;
  //     height: 50px;
  //     border: 10px solid #f3f3f3; /* Light grey */
  //     border-top: 10px solid #383636; /* Blue */
  //     border-radius: 50%;
  //     animation: custom_spinner 1.5s linear infinite;
  // }

}

$offset: 187;
$duration: 1.4s;

.app_spinner__material_svg {
  animation: custom_transition_rotate $duration linear infinite;
  width: 25px;
  height: 25px;
}

.app_spinner__material_svg_path {
  stroke: var(--custom-color-violet);
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: custom_transition_dash $duration ease-in-out infinite
}

@keyframes custom_spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes custom_transition_rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes custom_transition_dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: calc($offset/4);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
