@import '../../../../../../application/theme/Mixins.scss';
@import '../../../../../../application/theme/Colors.scss';

.resume_card {
    padding: 1.3125rem 1.6875rem;
    background-color: $white;
    border: 1px solid $grey-primary;

    &__heading {
        @include flexbox();
        margin-bottom: 0.5rem;

        h3 {
            margin: 0 1rem 0 0;
            font-size: 1rem;
        }
    }

    &__body {
        @include flexbox();
        margin-bottom: 0.5rem;
    }

    &__foot {
        @include flexbox();
    }

    &__foot_item {
        color: $grey-emphasis;
        font-size: 12px;
        line-height: 18px;
        display: inline-block;
        margin-right: 1.5rem;
        
        &:last-child {
            margin-right: 0;
        }
    }
}