.app_no_results {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	align-content: center;

	&__text {
		width: 100%;
		height: auto;

		.app_no_results_title {
			text-align: center;
			font-weight: 700;
			color: #676766;
		}

		.app_no_results_text {
			text-align: center;
			color: #000000;
		}
	}
}