.app_header {
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #EDEFF2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;

  &__user_info {
    min-width: 249px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .app_header_avatar {
      min-width: 158px;
      height: 100%;
      gap: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .avatar_name {
        min-width: 103px;
        font-size: 16px;
        font-weight: 500;
      }

      .avatar_image {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
        
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .app_header_notifications {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}