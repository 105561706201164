@import'../../../../application/theme/Colors.scss';

.login_form {
    background: $white;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgb($black, .1), 0 2px 4px -1px rgba($black, .06);
    margin: 0 auto;
    max-width: 25rem;
    min-height: 440px;
    padding: 0;
    width: 100%;

    &__heading {
        border-bottom: 1px solid rgba($black, 0.12);
        padding: 1rem 0;
        
        &_brand {
            margin: 0 auto;
            max-width: 6.25rem;
            width: 100%;

            img {
                max-width: 100%;
            }
        }
    }
    
    &__body {
        padding: 0.75rem 2.5rem;
        width: 100%;
    }

    &__title {
        font: normal normal 600 16px/24px 'Poppins', serif;
        text-align: center;
        color: $davys-grey;
        margin: 1rem 0;
    }

    &__control {
        color: rgb(112, 113, 122);
        
        label {
            + input:focus, + input:focus-visible {
                outline: var(--custom-color-violet) 1px solid;
                border-color: var(--custom-color-violet);
            }
        }

        input:focus + label{
            color: var(--custom-color-violet);
        }

        &_label {
            color: $sonic-silver;
            font-size: 0.875rem;
            font-weight: 500;
            width: 100%;
        }
        
        &_field {
            -webkit-tap-highlight-color: transparent;
            background: none;
            border: 1px solid rgba($border-color-field, 0.5);
            border-radius: 4px;
            color: currentcolor;
            display: block;
            margin: 0 auto 0.75rem;
            min-height: 2.5rem;
            min-width: 0px;
            padding: 8.5px 0px 8.5px 14px;
            width: 100%;
        }

        &_error {
            color: $custom-danger;
            font-size: 0.75rem;
            margin: 0 0 3px;
            position: relative;
            top: -0.5rem;
        }
    }

    &__submit {
        -webkit-tap-highlight-color: transparent;
        background-color: $violets-blue;
        border-radius: 4px;
        border: 0;
        box-shadow: 0px 3px 1px -2px rgba($black, .2), 0px 2px 2px 0px rgba($black, .14), 0px 1px 5px 0px rgba($black, .12);
        color: $white;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.75;
        margin: 1rem auto;
        min-height: 0;
        min-width: 0;
        min-width: 4rem;
        padding: 10px 16px;
        position: relative;
        -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        width: 100%;

        &[disabled] {
            background-color: rgba($black, .12);
            box-shadow: none;
        }
    }

}
