@import'../../../application/theme/Colors.scss';
@import'../../../application/theme/Mixins.scss';

.app_modal {
    @include align-items(center);
    @include flexbox;
    @include justify-content(center);
    background-color: rgba($black, .5);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
    z-index: 9;

    &.enter-done {
        opacity: 1;
        pointer-events: visible;
    }

    &.exit {
        opacity: 0;
    }

    &__header {
        padding: 0;
    }

    &__footer {
        @include flexbox;
        @include justify-content(flex-end);
        padding: 0;
        width: 100%;
    }

    &__content {
        @include flexbox;
        @include align-items(flex-start);
        background-color: $white;
        background: #FFFFFF;
        border-radius: 32px;
        flex-direction: column;
        gap: 10px;
        max-width: 41rem;
        padding: 32px 48px;
        transition: all 0.3s ease-in-out;
        width: 100%;
        .enter-done & {
            transform: translateY(0);
        }

        .exit & {
            transform: translateY(-12.5rem);
        }
    }

    &__title {
        align-items: center;
        color: #676766;
        display: flex;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.02em;
        line-height: 30px;
        margin: 0;
    }

    &__body {
        color: #B2B5C9;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.02em;
        line-height: 24px;
        padding: 0.5rem 0;

        p {
            margin: 0 0 1rem;
        }
    }

}


