.to_be_configure {
	width: 100%;
	display: flex;
	justify-content: center;
	align-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;

	&__title {
		width: 100%;
	}

	&__vacancy_list {
		width: 945px;
		height: auto;
		margin-top: 50px;
		display: flex;
		justify-content: center;
		align-content: flex-start;
		align-items: flex-start;
	}
}