.vacancy_button {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: white;
	border: 1px solid #EDEDED;
	padding: 0px 14px;
	cursor: pointer;

	&__left_section {
		width: 52%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.vacancy_title {
			width: 100%;
			font-weight: 700;
		}

		.vacancy_tags {
			width: 100%;
			height: 30px;
			display: flex;
			justify-content: flex-start;
			gap: 5px;
			margin-top: 5px;
		}
	}

	&__right_section {
		width: 48%;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.vacancy_status {
			width: auto;
			height: 100%;
			padding-top: 15px;
		}
	}
}