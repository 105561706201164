@mixin appearance {
    -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@mixin object-fit($value: cover) {
    -o-object-fit: $value;
	object-fit: $value;
} 

@mixin box-sizing($type) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    box-sizing: $type;
}

@mixin box-shadow( $h: 0px , $v: 0px , $b: 0px , $s: 0px , $c: #000 ) {
    -webkit-box-shadow: $h $v $b $s $c;
    -moz-box-shadow: $h $v $b $s $c;
    box-shadow: $h $v $b $s $c;
}

@mixin center-position($value: x) {
    position: absolute;
	@if $value == x {
        -webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		left: 50%;
    }
	@else if $value == y {
        -webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		top: 50%;
    }
	@else if $value == xy {
        -webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
    }
	@else if $value == reset {
        -webkit-transform: translate(0%, 0%);
		-moz-transform: translate(0%, 0%);
		-ms-transform: translate(0%, 0%);
		transform: translate(0%, 0%);
		position: inherit;
		top: inherit;
		left:  inherit;
    }
}


// Flex
@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -ms-flex: $values;
    -webkit-flex: $values;
    flex: $values;
}

@mixin flex-order($order) {
    -webkit-box-ordinal-group: $order;
    -moz-box-ordinal-group: $order;
    -ms-flex-order: $order;
    -webkit-order: $order;
    order: $order;
}

@mixin flex-direction($direction) {
    @if $direction == column {
        -webkit-flex-direction: vertical;
        -moz-flex-direction: vertical;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
    }
    @else {
        -webkit-flex-direction: horizontal;
        -moz-flex-direction: horizontal;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
    }
}

@mixin flex-dir-row($value: row) {
    flex-direction: $value;
	-webkit-box-direction: $value;
	-webkit-flex-direction: $value;
	-ms-flex-direction: $value;
}

@mixin flex-wrap($value: wrap) {
    flex-wrap: $value;
	-webkit-box-wrap: $value;
	-webkit-flex-wrap: $value;
	-ms-flex-wrap: $value;
}

@mixin align-items($value: center) {
    align-items: $value;
	-webkit-align-items: $value;
	-ms-flex-align: $value;
}
	

@mixin align-content($value: flex-start) {
    align-content: $value;
	-webkit-align-content: $value;
}

@mixin justify-content($value: center) {
    justify-content: $value;
	-webkit-justify-content: $value;
	-ms-flex-pack: $value;
	@if $value == flex-end {
        -ms-flex-pack: end;
    }
	@if $value == flex-start {
		-ms-flex-pack: start;
    }
	@if $value == space-around {
		-ms-flex-pack: justify;
    }
	@if $value == space-between {
		-ms-flex-pack: justify;
    }
}

@mixin order($value) {
    order: $value;
	-webkit-order: $value;
}

@mixin align-self($value: center) {
    align-self: $value;
	-webkit-align-self: $value;
}

// Gradient
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation == vertical {
        // vertical
        background: -moz-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(top, $start-color 0%, $end-color 100%);
        background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=0);
    }
    @else if $orientation == horizontal {
        // horizontal
        background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
        background: linear-gradient(to right, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    }
    @else {
        // radial
        background: -moz-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $start-color), color-stop(100%, $end-color));
        background: -webkit-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -o-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover, $start-color 0%, $end-color 100%);
        background: radial-gradient(ellipse at center, $start-color 0%, $end-color 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start-color', endColorstr='$end-color', GradientType=1);
    }
}

@mixin object-fit($value: cover) {
    -o-object-fit: $value;
	object-fit: $value;
}


// Composed Mixins

@mixin flexbox-align($axis) {
    @include flexbox;
    @include flex-wrap;
    @if $axis == x {
        @include justify-content(center);
    }
    @if $axis == y {
        @include align-items(center);
    }
    @if $axis == xy {
        @include justify-content(center);
        @include align-items(center);
    }
    @if $axis == r {
        display: block;
        @include justify-content(inherit);
        @include align-items(inherit);
    }
}