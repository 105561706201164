$white: #fff;
$black: #000;
$red: #FF0000;

// Naming colors form https://www.color-name.com/
$green-mantis: #5FC45B;
$tea-green: #C7EAC9;
$blue-cornflower: #65B1E9;
$violets-blue: #7F65F6;
$bright-gray: #EDEDEB;
$sonic-silver: rgb(112, 113, 122);
$davys-grey: #5e5e5e;
$grey-emphasis: #BDBDBD;

$yellow-primary: rgba(255, 188, 0, 0.2);
$yellow-secondary: #FFBC00;
$grey-primary: #EDEDED;
$grey-secondary: #676766;
$background-color-primary: #F5F7F9;

$custom-danger: $red !default;
$custom-success: $green-mantis !default;
$custom-info: $blue-cornflower !default;

$border-color-field: $sonic-silver !default;
