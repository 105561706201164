@import '../../theme/Colors.scss';

.app_tooltip {
    position: relative;

    &__icon {
        height: 1.125rem;
        width: 1.125rem;
    }

    &__content {
        font-weight: 400;
        line-height: 1.3;
        font-size: 12px;
        color: $white;
        padding: 3px 0;
        ol, ul {
            padding: 0;
            list-style-position: inside;
            margin: 0;
        }
    }
}
