.app_upload_file {
	width: 100%;
	height: 100%;
	border: 1px dashed #676766;
	border-radius: 10px;

	&__upload {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 15px;
		color: #676766;
		font-size: 16px;
		cursor: pointer;
	}

	&__loaded {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		row-gap: 10px;

		.file_name {
			width: 90%;
			min-height: 69px;
			background: #EDEDED;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: solid 1px #676766;
			border-radius: 10px;
			cursor: pointer;
			gap: 10px;

			&__icon {
				width: 15%;
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			&__text {
				width: 85%;
				height: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}

		.file_upload {
			width: 100%;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;
			color: #676766;
			font-size: 16px;
			cursor: pointer;

			&__text {
				text-decoration: underline;
			}
		}
	}
}