//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../theme/Mixins.scss';
@import '../theme/CustomProperties.scss';

* {
  @include box-sizing(border-box);
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

// Helper classes
.flex_box__centered {
  @include flexbox;
  @include flex-wrap;
}